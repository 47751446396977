/**
 * grid columns padding: 0.625rem;
 *
 * Breakpoints:
 *
 * Small only
 * @media screen and (max-width: 39.9375em) {}
 *
 * Medium and up
 * @media screen and (min-width: 40em) {}
 *
 * Medium only
 * @media screen and (min-width: 40em) and (max-width: 63.9375em) {}
 *
 * Large and up
 * @media screen and (min-width: 64em) {}
 *
 * Large only
 * @media screen and (min-width: 64em) and (max-width: 74.9375em) {}
 */

header,
main,
footer {
    position: relative;
}

header {z-index: 4;}
main {z-index: 2;}
footer {z-index: 3;}

header {
    background-color: #fff;
    background: #165F32;
    position: fixed;
    width: 100%;
    top: 0;
}

header .contact-and-creds {
    margin-top: 1.5rem; /* logo top padding */
    font-size: 1rem;
}

header nav,
.header-bottom-bar {
    background-color: #E5EEC8;
}

nav a {
    text-transform: capitalize;
    line-height: 1;
}

nav a.active {
    border-bottom: 2px solid #8f2643;
}

nav li {
    line-height: 1;
    display: inline-block;
    text-align: center;
    font-size: 1rem;
    padding: 0 0.5rem;
}

header ul {
    margin: 0;
    list-style: none;
}

header a {
    color: #fff;
}

header nav a {
    color: #3A3A3A;
}

header .hamburger {
    margin: 0;
    float: right;
}

.logo {
    font-weight: bold;
    background-repeat: no-repeat;
    background-image: url("images/logo.png");
    background-size: contain;
    text-align: center;
    margin: 0;
    font-size: 2rem;
    background-position: left;
    padding: 0 0 0 2rem;
}

.logo a {
    text-shadow: 0 3px 10px #000;
}

.logo sub {
    font-size: 0.5em;
    display: block;
}

.slidy-nav nav {
    padding-bottom: 0;
}

.header-bottom-bar {
    padding-top: .7rem; /* menu anchor padding */
}

.slidy-nav nav li:last-of-type a {
    padding-bottom: 0;
}

.move-offscreen {
    left: -100%;
    position: fixed;
}

.quick-contact ul {
    list-style: none;
    margin: 0;
}

.main-wrapper {
    padding-top: .5rem;
}

main {
    width:100%;
    min-height: 100%;
    min-height: 100vh;
}

.overlay {
    position: fixed;
    z-index: 9999;
    width: 100%;
    background: rgba(255, 255, 255, .5);
}

.copy-bar {
    font-size: 1rem;
    background-color: #165f32;
}

.copyrights {
    text-align: center;
    margin: 0;
}

footer a {
    color: #fff;
}

footer {
    color: #000;
}

footer .privacy-and-copy-right {
    display: table;
    width: 100%;
    height: 100%;
}

footer .privacy-and-social {
    display: table-cell;
    text-align: center;
    vertical-align: bottom;
}

footer .quick-contact p span {
    border-bottom: 1px solid #fff;
}

footer .quick-contact a {
    display: inline-block;
    padding-bottom: .5em;
    padding-top: .5em;
    width: 100%;
}

.creditations img {
    width: 100px;
    height: 100px;
}

footer .column-offset {
    padding: 1rem 0;
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 40em) {
    .logo {
        background-position: center;
        padding: 1.5rem 0;
    }

    header {
        padding: .625rem 0 0;
    }
}

.media-object-section + .media-object-section {
    display: block;
}

.media-object-section img {
    max-width: 100%;
}

@media screen and (min-width: 40em) {
    .media-object-section + .media-object-section {
        display: table-cell;
    }

    .media-object-section img {
        max-width: auto;
    }
}

.quick-contact a:hover {
    color: #ff93b1;
}
