.main-layout h2 {
    padding-top: 1rem;
    position: relative;
}

.main-layout h2:before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    top: 0;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(150, 150, 150, 0.75), rgba(0, 0, 0, 0));
}
