/** css-import only picks up double quotes for imports */
@import "normalize.css";
@import "foundation-sites/dist/css/foundation.css";
@import "hamburgers/dist/hamburgers.css";
@import "src/css/fonts.css";
@import "src/css/main.css";
@import "src/css/layout.css";

/* ========================================= Home */
.home-splash {
    height: 30vh;
}

.home-splash figure {
    width: 100%;
    height: 100%;
    position: relative;
}

.home-splash figcaption {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1rem;
}

.home-splash .caption-text {
    text-align: center;
    background-color: #fff;
    background-color: rgba(255, 255, 255, .5);
    padding: .5rem;
}

.home-splash .caption-text ul {
    margin: 0;
    margin-top: 1rem;
    list-style: none;
}

.home-splash .caption-text h1 {
    font-size: 1.5rem;
    text-transform: capitalize;
}

.layout-file-home .main-wrapper {
    padding-top: 0;
}

/* ========================================= What We Do */
.layout-file-what-we-do h2 {
    padding-left: 1rem;
}

.layout-file-what-we-do h1 {
    background-image: url('images/gb_geo.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 7rem 0 1rem;
}

.layout-file-what-we-do h1 span {
    background: #fff;
    padding: 0 1rem;
}

/* ========================================= Equipment */
.layout-file-equipment h2 sub {
    font-size: 0.75em;
}

/* ========================================= Contact */

/* ========================================= Case studies */

.layout-file-case-studies h2 {
    font-size: 1.5rem;
}

.layout-file-case-studies h3 {
    font-size: 1rem;
}

/* ========================================= media style overrides */

@media screen and (max-width: 39.9375em) {
    .home-splash {
        height: 50vh;
    }
}

@media screen and (min-width: 40em) {
    .layout-file-equipment h2 sub {
        display: inline-block;
    }
}
